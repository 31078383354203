import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Chat from './Chat'
import './ChatsContainer.css'

const ChatsContainer = (props) => {
  const { chats } = props

  const renderChats = () => {
    return chats.map((chat) => {
      return (
        <Tab key={chat.id} eventKey={`chat-${chat.id}`} title={chat.name} tabClassName={`${chat.color}-bg`}>
          <Chat chat={chat} />
        </Tab>
      )
    })
  }

  return (
    <Tabs defaultActiveKey='chat-1' transition={false} className='chats-container'>
      {renderChats()}
    </Tabs>
  )
}

export default ChatsContainer
