import './App.css'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

import firebase from 'firebase/app'
import 'firebase/auth'
import base, { firebaseApp } from './base'

import ChatsContainer from './components/ChatsContainer'
import VideoContainer from './components/VideoContainer'
/*import Login from './Login' */

/* const defaultVideos = [
  {
    id: 1,
    color: 'blue',
    frenchColor: 'bleu',
    title: 'Electro',
    url: 'https://www.youtube.com/watch?v=RzzOTieWPsk',
    volume: 0
  },
  {
    id: 2,
    color: 'green',
    frenchColor: 'vert',
    title: 'Chill',
    url: 'https://www.youtube.com/watch?v=36YnV9STBqc',
    volume: 0
  },
  {
    id: 3,
    color: 'red',
    frenchColor: 'rouge',
    title: 'Hardstyle',
    url: 'https://www.youtube.com/watch?v=7B-cD2cH9Zk',
    volume: 0
  }
] */

let viewsRef = null

class App extends Component {
  state = {
    chats: [],
    title: 'Silent at home',
    user: {
      uid: null
    },
    videos: [],
    views: {},
    activeVideoId: -1
  }

  componentDidMount () {

    this.videosRef = base.listenTo(`/videos`, {
        context: this,
        then(fvideos){
          this.handleNewVideos(fvideos)
        }
    })

    this.chatsRef = base.listenTo('/chats', {
        context: this,
        then(fchats){
          let chats = Object.keys(fchats).map(key => fchats[key])
          chats = chats.sort((chat1,chat2) => chat1.id > chat2.id);
          chats = chats.filter((chat) => {
            if (chat.visible === false)
            {
              return null
            }

            return chat
          })

          this.setState({chats: chats})
        }
    })

    this.viewsRef = base.syncState('/views', {
      context: this,
      state: 'views',
      then(data)
      {
        

      }
    })

  
    
  }

  saveLastData = () => {
    localStorage.setItem('views',JSON.stringify(this.state.views))
    let viewsString = localStorage.getItem('views')
    
    if (!viewsString)
    {
        return;
    }

    if (viewsRef)
    {
      viewsRef.onDisconnect().cancel()
    }
    else
    {
      viewsRef = firebaseApp.database().ref('views') 
    }

    let views = JSON.parse(viewsString);
    this.state.videos.forEach((video) => {
      if (video.id === this.state.activeVideoId && views[video.color] > 0)
      {
        views[video.color]--
        views['total']--
      }
    })
    
    viewsRef.onDisconnect().set(views)
  }

  componentWillUnmount () { 
        base.removeBinding(this.videosRef)
      base.removeBinding(this.chatsRef)
      base.removeBinding(this.viewsRef)
  }

  handleNewVideos = (fvideos) => {
    let videos = Object.keys(fvideos).map(key => fvideos[key])
    videos = videos.sort((video1,video2) => ('' + video1.color).localeCompare(video2.color))
    const isFirstTime = this.state.videos.length === 0
    let newViews = this.state.views

    let newVideos = videos.map((video) => {
      let color = video.color === 'blue' ? 'bleu' : video.color === 'green' ? 'vert' : 'rouge'

      if (isFirstTime || this.state.activeVideoId !== video.id)
      {
        if (!isFirstTime && newViews[video.color] > 0)
        {
          //newViews[video.color]--
        }

        return {
          ...video,
          volume: 0,
          frenchColor: color
        }
      }
      else if (video.id === this.state.activeVideoId)
      {
        return {
          ...video,
          frenchColor: color
        }
      }
      
      return {
        ...video,
        volume: 0,
        color: color
      }
    })

    newVideos = newVideos.filter((video) => {
      if (video.visible === false)
      {
        return null;
      }

      return video
    }) 

    this.setState({
      videos: newVideos,
      views: newViews
    })
  }

  handleListenCanal = (id) => {
    let cTitle = 'Silent at home'
    let newViews = this.state.views
    const oldActiveVideoId = this.state.activeVideoId
    let activeVideoId = id
    
    const newVideos = this.state.videos.map((video) => {
      if (oldActiveVideoId === video.id && newViews[video.color] > 0)
      {
        newViews[video.color]--
      }

      if (video.id === id) {
        cTitle = `${cTitle} - ${video.title} - Canal ${video.frenchColor}`
        activeVideoId = video.id
        
        if (video.volume === 0)
        {
          newViews[video.color]++
        }
        return {
          ...video,
          volume: video.volume === 0 ? 100 : 0
        }
      } else {
       
        return {
          ...video,
          volume: 0
        }
      }
    })

    newViews['total'] = newViews['blue'] + newViews['green'] + newViews['red']

    this.setState({
      activeVideoId: activeVideoId,
      title: cTitle,
      videos: newVideos,
      views: newViews
    },() => {
      this.saveLastData()
    })
  }

  renderVideos = () => {
   
    let videos = this.state.videos;
    return videos.map((video) => {
      let views = 0;
      let total = 0;
      
      if (this.state.views)
      {
        views = this.state.views[video.color]
        total = this.state.views['total']
      }

      return (
        <VideoContainer
          key={video.id}
          video={video}
          listenCanal={this.handleListenCanal}
          user={this.state.user}
          views={views}
          total={total}
        />
      )
    })
  }

  authenticate = () => {
    const authProvider = new firebase.auth.FacebookAuthProvider()
    firebaseApp.auth().signInWithPopup(authProvider).then(this.handleAuth)
  }

  handleAuth = async authData => {
    this.setState({
      user: authData.user
    })
  }

  render(){
    return (
      <Container fluid className='content'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{this.state.title}</title>
        </Helmet>
        <div className='header'>
          <h1 className='rainbow title'>Silent at home</h1>
          <br />
          <p className='rainbow subtitle'>
            <small>Par Genève Ville Vivante</small>
          </p>
        </div>
        <Row>
          <Col md={12}>
            <div className='videos-container'>{this.renderVideos()}</div>
          </Col>
          <Col md={12}>
            <ChatsContainer chats={this.state.chats} />
          </Col>
        </Row>
        
        { /* !user.uid ? (
          <Login authenticate={authenticate} />
        ) : '' */}
        
      </Container>
    )
  }
  
}

export default App
