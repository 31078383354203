import React, { useEffect } from 'react'
import { animateScroll } from 'react-scroll'
import Message from './Message'
import './MessagesBox.css'

const MessagesBox = (props) => {
  const messages = props.messages ? props.messages : []
  const { chat } = props

  const scrollToBottom = () => {
    animateScroll.scrollToTop({
      containerId: `messages-container-${chat.id}`
    })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const renderMessages = () => {
    if (!messages) {
      return <div>Aucun message</div>
    }

    const formattedMessages = Object.keys(messages).map(key => messages[key])
    return formattedMessages.map((message) => {
      return <Message key={message.id} message={message} />
    })
  }

  return (
    <div id={`messages-container-${props.chatId}`} className='messages-container'>
      {renderMessages()}
      <div name='last-fake-message'>&nbsp;</div>
    </div>
  )
}

export default MessagesBox
