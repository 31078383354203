import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import './ChatFooter.css'

const ChatFooter = (props) => {
  const [currentMessage, setCurrentMessage] = useState('')
  const [sending, setSending] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    if (currentMessage.length > 0) {
      setSending(true)
      props.addMessage(currentMessage)
      setSending(false)
      setCurrentMessage('')
    }
  }

  const handleChange = event => {
    const { value } = event.target
    setCurrentMessage(value)
  }

  return (
    <div className='chat-footer'>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId='message'>
          <Form.Control type='text' placeholder='Votre message..' onChange={handleChange} disabled={sending} value={currentMessage} />
        </Form.Group>
      </Form>
    </div>
  )
}

export default ChatFooter
