import React from 'react'
import Moment from 'react-moment'
import './Message.css'

const Message = (props) => {
  const { message } = props

  const randomNumber = Math.floor(Math.random() * 100)
  const msgClass = randomNumber % 2 === 0 ? `message ${message.color}-bg` : `message mine ${message.color}-bg`

  return (
    <div className='message-container'>
      <div className={msgClass}>
        <p>{message.content}</p>
        <div className='detailsContainer'>
          <span>
            <Moment format='HH:mm'>
              {message.createdAt}
            </Moment>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Message
