import Rebase from 're-base'
import firebase from 'firebase/app'
import 'firebase/database'

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyA26iDuNrqhd4y_r-1paMEBaC3mTQG2sIM',
  authDomain: 'silent-gva.firebaseapp.com',
  databaseURL: 'https://silent-gva-default-rtdb.europe-west1.firebasedatabase.app'
})

const base = Rebase.createClass(firebaseApp.database())

// This is a named export
export { firebaseApp }

// this is a default export
export default base
