import React, { Component } from 'react'
import MessageBox from './MessagesBox'
import ChatFooter from './ChatFooter'

import './Chat.css'
import base from '../base'

class Chat extends Component {
  state = {
      chat: {},
      loading: false,
      sending: false
  }

  componentDidMount () {
    this.ref = base.syncState(`/chats/chat-${this.props.chat.id}`, {
        context: this,
        state: 'chat',
        then(data){
            this.setState({loading: false})
        }
    })
  }

  componentWillUnmount () {
      base.removeBinding(this.ref)
  }

  getKey = (id) => 
  {
      return `message-${id}`;
  }

  addMessage = (currentMessage) => {
      const chat = { ...this.state.chat }
      const messages = chat.messages ? chat.messages : {}
      const date = Date.now()
      const id = date
      let message = {
          id: id,
          content: currentMessage,
          createdAt: date,
          color: chat.color
      }
      messages[this.getKey(id)] = message
      chat.messages = messages

      this.setState({
        sending: false,
        chat: chat
      })
  }
  
  render () {
    return (
      <div className='chat-container'>
          {this.state.loading ? 'Chargement...' : (
              <>
                <ChatFooter addMessage={this.addMessage}/>
                <MessageBox messages={this.state.chat.messages} chat={this.state.chat}/>
              </>
        )}
      </div>
    )
  }
}

export default Chat;