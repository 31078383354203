import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { Button, Modal, ProgressBar } from 'react-bootstrap'
import './VideoContainer.css'

const VideoContainer = (props) => {
  const { total, user, video, views } = props
  const [show, setShow] = useState(false)

  const isActive = video.volume !== 0

  let videoClass = `video-container ${video.color}`
  if (isActive) {
    videoClass += ' active'
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const percent = total === 0 ? 0 : views / (total / 100)
  const percentColor = video.color === 'green' ? 'success' : video.color === 'blue' ? 'primary' : 'danger'

  return (
    <div className={videoClass}>
      <h3>{video.title}</h3>
      <ReactPlayer
        url={video.url}
        controls
        width='100%'
        playing
        volume={video.volume}
        onPause={() => props.listenCanal(video.id)}
      />

      {total > 0
        ? (
          <div className='white-bg radius'>
            <ProgressBar striped variant={percentColor} now={percent} label={`${Math.round(percent)}%`} className='progress-views' />
          </div>
          )
        : ''}

      {user.uid === '3lG00uqcVHhTxIzsBGoEaOubO8r1'
        ? (
          <button
            className='btn-primary'
            onClick={handleShow}
          >
            Modifier
          </button>
          )
        : ''}

      {!isActive
        ? (
          <button
            className={`btn ${video.color}-bg`}
            onClick={() => props.listenCanal(video.id)}
          >
            Ecouter ce canal
          </button>
          )
        : (
          <button
            className={`btn ${video.color}-bg`}
            onClick={() => props.listenCanal(-1)}
          >
            Ne plus écouter
          </button>
          )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default VideoContainer
